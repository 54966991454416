import React from 'react'

const SelectNetwork = (props) => {

    const {currentNetwork, updateNetwork} = props;

    return (
        
        <div aria-label="Basic example" style={styling}>
            <button type="button" class={currentNetwork=="Hallmark Channel" ? "btn btn-secondary active": "btn btn-secondary"} style={ currentNetwork=="Hallmark Channel" ? buttonActive : buttonInActive}>
            <img src={"/images/network-logo/HC-white.png"} style={{width:"90px",height:'40px'}} onClick={() => updateNetwork("Hallmark Channel")}></img>
            </button>

            <button type="button" class={currentNetwork=="Hallmark Plus" ? "btn btn-secondary active": "btn btn-secondary"} style={ currentNetwork=="Hallmark Plus" ? buttonActive : buttonInActive}>
            <img src={"/images/network-logo/HP_White.png"} style={{width:"100px"}} onClick={() => updateNetwork("Hallmark Plus")}></img>
            </button>

            <button type="button" class={currentNetwork=="Hallmark Movies & Mysteries" ? "btn btn-secondary active": "btn btn-secondary"} style={ currentNetwork=="Hallmark Movies & Mysteries" ? buttonActive : buttonInActive}>
            <img src={"/images/network-logo/HMLogo.png"} style={{width:"112px"}} onClick={() => updateNetwork("Hallmark Movies & Mysteries")}></img>
            </button>

            

            {/* <button type="button" class={currentNetwork=="Hallmark Movies Now" ? "btn btn-secondary active": "btn btn-secondary"} style={ currentNetwork=="Hallmark Movies Now" ? buttonActive : buttonInActive}>
            <img src={"/images/network-logo/HMN_logo_white_alt.png"} style={{width:"90px", height:'40px'}} onClick={() => updateNetwork("Hallmark Movies Now")}></img>
            </button> */}

            <button type="button" class={currentNetwork=="Hallmark Drama" ? "btn btn-secondary active": "btn btn-secondary"} style={ currentNetwork=="Hallmark Drama" ? buttonActive : buttonInActive}>
            <img src={"/images/network-logo/HF_LOGO_White.png"} style={{width:"84px"}} onClick={() => updateNetwork("Hallmark Drama")}></img>
            </button>
        </div>
      
        
    );

}

export default SelectNetwork;

const styling = {
    padding:'20px'
}

const buttonActive = {
    //backgroundColor:'#4a317c',
    backgroundColor:'rgb(220,175,34)',
    marginRight:'4px',
    borderColor:'white',
    borderRadius:'4px',
    
}

const buttonInActive = {
    backgroundColor:'rgba(74,49,124,0.48)',
    marginRight:'4px',
    borderColor:'white',
    borderRadius:'4px'
}
