import React from "react";
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import StyledFooterWrapper from '../styling/StyledFooterWrapper';


const Footer = () => {
    
    return (
        <StyledFooterWrapper>
            <div className="hc-footer top-shadow">
                <Container>
                    <footer className='footer footer-div'>
                        <div className="navigation-footer-branding">
                            <Link to='/'><img className="footer-logo" src='/images/HallmarkMedia_Logo_White.png' alt="Hallmark Channel Logo" /></Link>
                        </div>
                        <div className="media-socialLinks">
                            <p></p>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/hallmarkchannel/"><i className="media-socialLinks-item-link soc-icon fab fa-facebook-f"></i></a>
                            </div>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC5T-sc4bz1eAEgMIamLLV6w"><i className="media-socialLinks-item-link soc-icon fab fa-youtube"></i></a>
                            </div>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hallmarkchannel?lang=en"><i className="media-socialLinks-item-link soc-icon fab fa-twitter"></i></a>
                            </div>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="http://www.pinterest.com/hallmarkchannel"><i className="media-socialLinks-item-link soc-icon fab fa-pinterest"></i></a>
                            </div>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hallmarkchannel/?hl=en"><i className="media-socialLinks-item-link soc-icon fab fa-instagram"></i></a>
                            </div>
                            <div className="media-socialLinks-item">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hallmark-channel"><i className="media-socialLinks-item-link soc-icon fab fa-linkedin"></i></a>
                            </div>
                        </div>

                        <ul className="navigation-footer-items">

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmarkchannel.com/">Hallmark Channel</a></span>
                            </li>

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmarkplus.com/">Hallmark Plus</a></span>
                            </li>

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmarkmystery.com/">Hallmark Mystery</a></span>
                            </li>

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmarkfamily.com/">Hallmark Family</a></span>
                            </li>

                           

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://corporate.crownmedia.com/#/networks">About Us</a></span>
                            </li>

                            {/* <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href='https://cm-press-site-2021.s3.amazonaws.com/AFFM21_Publicity_ContactSheet_r1b.jpg'>Network Contacts</a></span>
                            </li> */}

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href='https://corporate.crownmedia.com/#/about-us/executives'>Executive Leadership</a></span>
                            </li>


                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmark.com/privacy/">Privacy Policy</a></span>
                            </li>
                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmark.com/privacy-notice/">CA Privacy Notice</a></span>
                            </li>

                            <li className="navigation-footer-item">
                                <span className="navigation-footer-item-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hallmark.com/terms-of-use/">Terms of Use</a></span>
                            </li>

                        </ul>
                        <div className="navigation-footer-copyright">Copyright &copy; 2024 Hallmark Media, all rights reserved</div>

                    </footer>
                </Container>
            </div>
        </StyledFooterWrapper>
    )
}

export default Footer;